.b-socialproof {
	background-color: rgba($color-light-grey, 0.66);
	border-radius: 2px;
	bottom: 0;
	cursor: default;
	left: 50%;
	max-width: 414px;
	padding: 10px 40px 10px 10px;
	position: absolute;
	transform: translateX(-50%);
	z-index: 1;

	@include media(sm) {
		max-width: 100%;
		padding: 5px 35px 5px 10px;
	}

	.l-pdp.m-idea & {
		display: none;
	}

	&.m-tile {
		max-width: 100%;
		padding: 5px;
		text-align: center;
		width: calc(100% - 20px);

		@include media(md-down) {
			bottom: 26px;
		}
	}

	&-close {
		cursor: pointer;
		position: absolute;
		right: 7px;
		top: 7px;

		svg {
			height: 18px;
			width: 18px;

			@include media(sm) {
				height: 16px;
				width: 16px;
			}
		}
	}

	&-content {
		align-items: center;
		display: flex;
		font-size: 19px;
		justify-content: center;
		line-height: 28.5px;

		@include media(sm) {
			font-size: 15px;
			line-height: 21px;
		}

		&.m-tile {
			@include media(sm) {
				font-size: 14px;
				line-height: 20px;
			}
		}

		&.m-tile svg {
			height: 33px;
			width: 33px;

			@include media(sm) {
				height: 22px;
				width: 22px;
			}
		}
	}

	&-title {
		font-weight: 600;
	}

	&-inner {
		padding-left: 20px;
		width: max-content;

		@include media(sm) {
			padding-left: 10px;
		}

		&.m-tile {
			padding-left: 5px;
		}
	}
}
