/*md

# b-global_alerts

This alerts used to notify blind user about dynamic changes on the page. Ex: load more products,
filters applied, sorting applied etc. To meet basic a11y requirements.

It is recommended to not hide this alerts and make them visible - it ease of testing
and fix as soon as it broken.

This messages should not have prominent visual styles.

```html_example
<div class="b-global_alerts">
    <div
        class="b-global_alerts-item"
        role="alert"
    >
        Filters applied
    </div>
</div>
```

## What this alerts covers

This is middle level between page and components.

Please see more [info](https://confluence.ontrq.com/pages/viewpage.action?pageId=228864950)

## Visually hide alerts

It is possible to hide this alerts visually, but it is not recommended.

We got special modificator to do so - `m-visually_hidden`. Please see hideAccessibilityAlerts site pref to more info.

*/

.b-global_alerts {
	bottom: 20px;
	left: 50%;
	position: fixed;
	text-align: center;
	transform: translateX(-50%);
	z-index: z(notifier);

	&.m-visually_hidden {
		@include hide(visually);
	}

	&-item {
		animation: slide-from-bottom $motion-ease;
		background-color: $color-accent-light;
		color: $color-primary;
		cursor: default;
		display: inline-block !important; //stylelint-disable-line
		font-size: 15px;
		line-height: 1.5;
		margin: 0 auto;
		max-width: 400px;
		min-width: 206px;
		padding: 10px 40px;

		&[hidden] {
			@include hide(visually);

			pointer-events: none;
		}
	}
}
