/*md

# b-price

Designed to provide same styles of pricing across different components without explicit CSS class.
`.b-price` inherit font-size from parent wrapper or general font-size

## Regular price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Sale price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">Was</span>
	<span class="b-price-item m-old" content="11.99" data-tau-price="old">
		$11.99
	</span>
	<span class="b-price-to">, is</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
</div>
```

## Price range

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-to">From</span>
	<span class="b-price-item" content="9.99" data-tau-price="new">
		$9.99
	</span>
	<span class="b-price-to">to</span>
	<span class="b-price-divider">-</span>
	<span class="b-price-item" content="11.99" data-tau-price="old">
		$11.99
	</span>
</div>
```

## Free price

```html_example
<div class="b-price" data-tau-price="product_price">
	<span class="b-price-item m-free" content="Free" data-tau-price="new">
		FREE
	</span>
</div>
```

*/

.b-price {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	font-weight: 600;
	line-height: 1;
	position: relative;

	&-item {
		display: inline-block;
		white-space: nowrap;

		.m-checkout_shipping & {
			color: $color-primary;
			font-size: 15px;
			font-weight: 600;
			line-height: 22px;
		}

		&:last-child {
			margin: 0;
		}

		&.m-new {
			margin-right: 8px;
		}

		&.m-old {
			font-weight: 600;
			margin-right: 8px;
			text-decoration: line-through;

			.b-product_bundle-subtotal & {
				margin-right: 0;
			}
		}

		&.m-new ~ & {
			color: $color-text-dimmed;
			font-weight: 500;
			text-decoration: line-through;
		}

		&.m-free {
			font-size: 20px;
			font-weight: 700;

			.m-checkout_shipping & {
				font-size: 15px;
				font-weight: 600;
				line-height: 22px;
			}
		}

		&.m-total {
			font-size: inherit;
			font-weight: 600;
			text-transform: uppercase;
		}

		&.m-colored {
			color: $color-accent;
		}

		.b-minicart_product.m-checkout & {
			margin-right: 4px;
		}
	}

	&-divider {
		margin: 0 5px;

		&.m-range {
			margin: 0 4px;
		}
	}

	&-subtotal {
		font-weight: 600;
		line-height: 1.2;
		text-align: right;

		@include media(sm) {
			display: flex;
		}
	}

	&-subtotal_item {
		&.m-old {
			color: $color-text-dimmed;
			text-decoration: line-through;

			@include media(sm) {
				margin-left: 8px;
			}
		}
	}
}
