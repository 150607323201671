/*md

# b-button

Please see [g-button](./02-components-g-button.html) for details.

*/

.b-button {
	@include g-button;

	background: $color-action-obj;
	border: 1px solid $color-action-obj;
	border-radius: $controls-radius;
	color: $color-action-obj-text;
	font-size: 15px;
	font-weight: 500;
	line-height: 15px;
	padding: 0 10px;

	@include hover-supported {
		&:hover {
			background: $color-action-dark;
			border-color: $color-action-dark;
			text-decoration: none;
		}
	}

	&.m-outline {
		background-color: $color-bg;
		border-color: $color-divider;
		color: $color-title;

		@include hover-supported {
			&:hover {
				background: $color-action-obj;
				border-color: $color-action-obj;
				color: $color-action-obj-text;
			}
		}
	}

	&:disabled,
	&.m-disabled {
		@include g-button(disabled);
	}

	&.m-link {
		@include g-link;

		background: transparent;
		border-color: transparent;
		color: inherit;
		font-weight: 600;
		letter-spacing: 0;
		padding: 0;
	}

	&.m-small {
		border-radius: $controls-radius-small;
		font-size: 13px;
		height: $size-input-height-small;
		padding: 0 20px;
	}

	&.m-width_full {
		width: 100%;
	}

	&.m-sale {
		background-color: $color-fg-promo;
		border-color: $color-fg-promo;

		@include hover-supported {
			&:hover {
				background: $color-fg-promo-hover;
				border-color: $color-fg-promo-hover;
			}
		}
	}

	&.m-pills {
		background-color: $color-bg-tag;
		border-color: $color-bg-tag;

		@include hover-supported {
			&:hover {
				background: $color-bg-tag-hover;
				border-color: $color-bg-tag-hover;
			}
		}
	}

	&.m-icon_only {
		padding-left: 15px;
		padding-right: 15px;
	}

	&-icon {
		margin-right: 10px;
	}

	&.m-icon_only &-icon {
		margin-right: 0;
	}

	&.m-promo {
		background-color: $color-pink;
		border-color: $color-pink;
		padding: 0 25px;
	}

	&.m-lozenge {
		background: none;
		border: 1px solid $color-burgundy;
		border-radius: 4px;
		color: $color-burgundy;
		height: initial;
		padding: 12px;
	}
}
